import { getWorkdayUserDetail } from '@/api/msp/workdayUser'
export const indexMixins = {
  data () {
    return {
      timeTypeArray: [
        { value: 1, name: '晚班' },
        { value: 2, name: '白班' },
        { value: 3, name: '全天' }
      ],
      staffTypeArray: [
        { value: 1, name: '外聘人员' },
        { value: 2, name: '内部员工' }
      ],
      priorityLevelArray: [
        { value: 1, name: '高' },
        { value: 2, name: '中' },
        { value: 3, name: '低' }
      ]
    }
  },
  methods: {
    async getUserInfo (userId) {
      const result = await getWorkdayUserDetail({ userId: userId })
      return result
    }
  }
}
