<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="8">
        <DatePicker
          v-model="dateRange"
          :options="dateOptions"
          type="daterange"
          size="small"
          split-panels
          transfer
          placeholder="选择请假日期"
          style="width:100%"
          @on-change="onChangeDate"
        ></DatePicker>
      </i-col>
      <i-col span="4">
        <Button
          type="primary"
          size="small"
          :disabled="dateRange.length===0"
          @click="onAdd"
        >添加请假日期</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        size="small"
        :data="list"
        :columns="tableColumns"
      />
    </div>
  </div>
</template>

<script>
import { setUserLeavePeriod } from '@/api/msp/workdayUser'
export default {
  props: {
    userId: [Number],
    records: {
      type: Array,
      default: new Array(0)
    }
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 86400000
        }
      },
      dateRange: [],
      formData: {
        userId: null,
        startDate: '',
        endDate: ''
      },
      list: [],
      tableColumns: [
        {
          title: '请假时间',
          align: 'center',
          render: (h, { row }) => {
            return h('span', `${row.startDate} 至 ${row.endDate}`)
          }
        },
        { title: '创建时间', align: 'center', key: 'createTime' },
        { title: '创建人', align: 'center', key: 'createUserName' }
      ]
    }
  },
  methods: {
    onChangeDate (date) {
      this.formData.startDate = date[0]
      this.formData.endDate = date[1]
    },
    onAdd () {
      this.formData.userId = this.userId
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: async () => {
          const result = await setUserLeavePeriod(this.formData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.$emit('refreshInfo')
          }
        }
      })
    }
  },
  watch: {
    records: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.list = val
        }
      }

    }
  }
}
</script>
