<template>
  <div>
    <Modal
      v-model="detailModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >人员详情</p>
      <div style="max-height: 680px;overflow-y: auto; overflow-x:hidden;">
        <h4 class="workplatform-title m-t-10 m-b-10">基础信息</h4>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <span class="title">姓名</span><span>{{ info.name }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">联系方式</span><span>{{ info.phone }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">地址</span><span>{{ info.province }}</span><span>{{ info.city }}</span><span>{{ info.county }}</span><span>{{ info.addressInfo }}</span>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <span class="title">工作时段</span><span>{{ info.workTimeTypeStr }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">优先级</span><span>{{ info.priorityLevelStr }}</span>
          </i-col>
        </Row>
        <div>
          <Tabs v-model="curTab">
            <TabPane
              label="请假时段"
              name="leave"
            >
              <leave :userId="info.id" :records="info.leaveRecordList||[]" @refreshInfo="refreshInfo" />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { indexMixins } from '../mixins'
import Leave from './Leave.vue'
export default {
  mixins: [indexMixins],
  components: { Leave },
  data () {
    return {
      detailModal: false,
      info: {},
      curTab: 'leave'
    }
  },
  methods: {
    showModal (userId) {
      this.curTab = 'leave'
      this.getData(userId)
      this.detailModal = true
    },
    async getData (userId) {
      const result = await this.getUserInfo(userId)
      if (result && !result.errcode) {
        this.info = result
      }
    },
    refreshInfo () {
      this.getData(this.info.id)
    }
  }
}
</script>
