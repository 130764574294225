import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 创建或更新代班人员
 * @param {Object} data
 * @returns
 */
export function createOrUpdateUser (data) {
  return request.post('/ooh-msp/v1/workdayUser/createOrUpdateUser', qs.stringify(data))
}

/**
 * 获取代班人员信息明细
 * @param {Object} data
 * @returns
 */
export function getWorkdayUserDetail (data) {
  return request.post('/ooh-msp/v1/workdayUser/getWorkdayUserDetail', qs.stringify(data))
}

/**
 * 分页获取代班人员列表
 * @param {Object} data
 * @returns
 */
export function getWorkdayUserPage (data) {
  return request.post('/ooh-msp/v1/workdayUser/getWorkdayUserPage', qs.stringify(data))
}

/**
 * 设置人员休假时段
 * @param {Object} data
 * @returns
 */
export function setUserLeavePeriod (data) {
  return request.post('/ooh-msp/v1/workdayUser/setUserLeavePeriod', qs.stringify(data))
}

/**
 * 配置站点信息
 * @param {Object} data
 * @returns
 */
export function setUserStations (data) {
  return request.post('/ooh-msp/v1/workdayUser/setUserStations', qs.stringify(data))
}

/**
 * 删除用户
 * @param {Object} data
 * @returns
 */
export function setUserUnusable (data) {
  return request.post('/ooh-msp/v1/workdayUser/setUserUnusable', qs.stringify(data))
}
